import axios from 'axios'
import { Notification, MessageBox, Message } from 'element-ui'
// import errorCode from '@/utils/errorCode'

axios.defaults.headers['Content-Type'] = 'application/json;charset=UTF-8'
// 创建axios实例
const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  //baseURL: 'http://192.168.0.30:8080/',
  // baseURL: 'http://www.xgbkj.com:8091',
  //  baseURL: 'http://47.108.30.238:8091',
  baseURL: 'https://www.zhipaixingong.com',
  // 超时
  timeout: 5000
})

// request拦截器，每个接口请求的时候加token
service.interceptors.request.use(config => {
  let token = sessionStorage.getItem('token'); // 取你存放的token
  if (token) {
    config.headers.common['token'] = token; // 中括号里请求头里的名字 Authorization一般这个名字是token常用的 在这里公司后端要token这个名字 不要轻易更改
  }
  return config
}, error => {
  console.log(error)
  Promise.reject(error)
})

// 响应拦截器
service.interceptors.response.use(res => {
  // 未设置状态码则默认成功状态
  const code = res.data.code || 200;
  const status = res.status;
  // 获取错误信息
  const msg = res.data.data;
  //获取错误提示
  const ress = res.data.message;
  console.log(res);
  console.log(msg, 'msg');
  console.log(ress, 'ress');
  if (code === 601) {
    MessageBox.confirm('登录状态已过期，您可以继续留在该页面，或者重新登录', '系统提示', {
      confirmButtonText: '重新登录',
      cancelButtonText: '取消',
      type: 'warning'
    }
    ).then(() => {
      location.href = '/Login';
      localStorage.clear()
    })
  } else if (code === 500 || status === 500) {
    Message({
      message: ress,
      type: 'error'
    })

    return Promise.reject(new Error(msg))
  } else if (code !== 200) {

    Notification.error({
      title: ress
    })


    return Promise.reject('error')
  } else {
    return res.data
  }
},
  error => {
    console.log('err' + error)
    let { message } = error;
    if (message == "Network Error") {
      message = "后端接口连接异常";
    }
    else if (message.includes("timeout")) {
      message = "系统接口请求超时";
    }
    else if (message.includes("Request failed with status code")) {
      message = "系统接口" + message.substr(message.length - 3) + "异常";
    }
    Message({
      message: message,
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)

export default service

