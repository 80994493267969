<template>
  <div class="home">
    <div class="topbg">
      <video src="../assets/菜鸟图库-狗在大自然中.mp4" height="100%" width="100%" autoplay muted loop></video>
      <div class="topsub">

        <img src="../assets/logo@2x.png" alt="" width="259px" height="54px">

        <div class="toptitle">
          <div class="toptitletext">首页</div>
          <div class="toptitletext" @click="scrolltotese">产品特色</div>
          <div class="toptitletext" @click="scrolltonews">新闻资讯</div>
          <div class="toptitletext" @click="scrolltous">关于我们</div>

        </div>
      </div>
      <div class="centertitel">
        <div class="centertiteltext1">智派新工</div>
        <div class="centertiteltext2">灵活用工新选择 · 工资日结更自由</div>
      </div>
    </div>

    <div class="bgzoom" :style="{ zoom: zoom }">
      <!-- 公司简介 -->
      <div class="companybg">
        <div class="companycontent">
          <div class="companycontent-left">
            <img src="../assets/company profile@2x.png" alt="" width="612px" height="80px">
            <div class="companycontent-left-title ">公司简介</div>
            <div class="companycontent-left-content">
              智派新工是一款专注于灵活用工的创新型人力资源APP，致力于为企业和个人提供高效、便捷、安全的用工解决方案。我们的平台结合了当前灵活就业市场的需求，推出“日结工资、线上结算”模式，让工人随时随地领取工资，同时帮助企业优化用工成本，提高管理效率。
            </div>
          </div>
          <img src="../assets/shou.png" alt="" width="730px" height="770px">
        </div>

        <div class="companylist">
          <div class="companylistitem">
            <div class="companylistitem-img">
              <img src="../assets/hl1.png" alt="" width="64px" height="64px" class="companylistitem-imgactive">
              <img src="../assets/hl2.png" alt="" width="64px" height="64px" class="companylistitem-imgunactive">
            </div>
            <div class="companylistitem-text">海量零工</div>
          </div>
          <div class="companylistitem">
            <div class="companylistitem-img">
              <img src="../assets/rj1.png" alt="" width="64px" height="64px" class="companylistitem-imgactive">
              <img src="../assets/rj2.png" alt="" width="64px" height="64px" class="companylistitem-imgunactive">
            </div>
            <div class="companylistitem-text">短工日结</div>
          </div>
          <div class="companylistitem">
            <div class="companylistitem-img">
              <img src="../assets/jz1.png" alt="" width="64px" height="64px" class="companylistitem-imgactive">
              <img src="../assets/jz2.png" alt="" width="64px" height="64px" class="companylistitem-imgunactive">
            </div>
            <div class="companylistitem-text">精准匹配</div>
          </div>
          <div class="companylistitem">
            <div class="companylistitem-img">
              <img src="../assets/zs1.png" alt="" width="64px" height="64px" class="companylistitem-imgactive">
              <img src="../assets/zs2.png" alt="" width="64px" height="64px" class="companylistitem-imgunactive">
            </div>
            <div class="companylistitem-text">真实职位</div>
          </div>
          <div class="companylistitem">
            <div class="companylistitem-img">
              <img src="../assets/kk1.png" alt="" width="64px" height="64px" class="companylistitem-imgactive">
              <img src="../assets/kk2.png" alt="" width="64px" height="64px" class="companylistitem-imgunactive">
            </div>
            <div class="companylistitem-text">工人可靠</div>
          </div>
        </div>
      </div>
      <!-- 特色 -->
      <div class="tsbg" id="tese">
        <div><img src="../assets/tese.png" alt="" width="636px" height="104px"></div>
        <div class="teselist">
          <div class="teselistitem" @click="tesechange(1)">
            <div :class="[teseindex == 1 ? 'activetesetitle' : 'tesetitle']">工资日结，灵活提现</div>
            <div class="teseline" v-if="teseindex == 1"></div>
          </div>
          <div class="teselistitem" @click="tesechange(2)">
            <div :class="[teseindex == 2 ? 'activetesetitle' : 'tesetitle']">智能匹配，高效对接</div>
            <div class="teseline" v-if="teseindex == 2"></div>
          </div>
          <div class="teselistitem" @click="tesechange(3)">
            <div :class="[teseindex == 3 ? 'activetesetitle' : 'tesetitle']">合规用工，降低风险</div>
            <div class="teseline" v-if="teseindex == 3"></div>
          </div>
          <div class="teselistitem" @click="tesechange(4)">
            <div :class="[teseindex == 4 ? 'activetesetitle' : 'tesetitle']">移动操作，简单便捷</div>
            <div class="teseline" v-if="teseindex == 4"></div>
          </div>
          <div class="teselistitem" @click="tesechange(5)">
            <div :class="[teseindex == 5 ? 'activetesetitle' : 'tesetitle']">我们的口号</div>
            <div class="teseline" v-if="teseindex == 5"></div>
          </div>
        </div>
        <div class="tesecontenttitle" v-if="teseindex == 1">
          告别传统工资结算周期，工人可自主提现，真正实现“我的工资，我做主”
        </div>
        <div class="tesecontenttitle" v-if="teseindex == 2">
          先进算法有效对接人才效，节省时间成本，提升资源配置效率，让双方交流无阻，快速敲定合作细节
        </div>
        <div class="tesecontenttitle" v-if="teseindex == 3">
          合规用工，有效规避潜在风险，保障用工方日常运营的平稳有序，为长远战略目标的实现奠定坚实基础
        </div>
        <div class="tesecontenttitle" v-if="teseindex == 4">
          线上移动端操作简单便捷，高效服务极大提升了生活与工作的效率及体验
        </div>
        <div class="tesecontenttitle" v-if="teseindex == 5">
          告别传统工资结算周期，工人可自主提现，真正实现“我的工资，我做主”
        </div>
        <div class="teseimglist">
          <div class="swiper-container">
            <div class="swiper-wrapper">
              <div v-for="(item, index) in teseimglist" :key="index" class="swiper-slide">
                <img :src="item" alt="" width="512px" height="782px" @click="toteimg(index)">
              </div>
            </div>
            <!-- Add Pagination -->

          </div>
        </div>
      </div>
      <!-- 流程 -->
      <div class="lczw"></div>
      <div class="lcbg">
        <div><img src="../assets/lc.png" alt="" width="780px" height="106px"></div>
        <div class="lclist">
          <div class="lclistitem" @click="changelcindex(1)">
            <div :class="[lcindex >= 1 ? 'activelcyuan' : 'lcyuan']"></div>
            <div :class="[lcindex >= 1 ? 'activelcindex' : 'lcindex']">01</div>
            <div :class="[lcindex >= 1 ? 'activelctitle' : 'lctitle']">时长选择</div>
          </div>
          <div class="lclistitem" @click="changelcindex(2)">
            <div :class="[lcindex >= 2 ? 'activelcyuan' : 'lcyuan']"></div>
            <div :class="[lcindex >= 2 ? 'activelcindex' : 'lcindex']">02</div>
            <div :class="[lcindex >= 2 ? 'activelctitle' : 'lctitle']">零工抢单</div>
          </div>
          <div class="lclistitem" @click="changelcindex(3)">
            <div :class="[lcindex >= 3 ? 'activelcyuan' : 'lcyuan']"></div>
            <div :class="[lcindex >= 3 ? 'activelcindex' : 'lcindex']">03</div>
            <div :class="[lcindex >= 3 ? 'activelctitle' : 'lctitle']">签订合同</div>
          </div>
          <div class="lclistitem" @click="changelcindex(4)">
            <div :class="[lcindex >= 4 ? 'activelcyuan' : 'lcyuan']"></div>
            <div :class="[lcindex >= 4 ? 'activelcindex' : 'lcindex']">04</div>
            <div :class="[lcindex >= 4 ? 'activelctitle' : 'lctitle']">工作需求</div>
          </div>
          <div class="lclistitem" @click="changelcindex(5)">
            <div :class="[lcindex >= 5 ? 'activelcyuan' : 'lcyuan']"></div>
            <div :class="[lcindex >= 5 ? 'activelcindex' : 'lcindex']">05</div>
            <div :class="[lcindex >= 5 ? 'activelctitle' : 'lctitle']">我的招工</div>
          </div>
          <div class="lcline1"></div>
          <div class="lcline2" v-if="!(lcindex >= 2)"></div>
          <div class="activelcline2" v-if="lcindex >= 2"></div>
          <div class="lcline3" v-if="!(lcindex >= 3)"></div>
          <div class="activelcline3" v-if="lcindex >= 3"></div>
          <div class="lcline4" v-if="!(lcindex >= 4)"></div>
          <div class="activelcline4" v-if="lcindex >= 4"></div>
          <div class="lcline5" v-if="!(lcindex >= 5)"></div>
          <div class="activelcline5" v-if="lcindex >= 5"></div>
          <div class="lcline6"></div>



        </div>
        <div class="lccontent">
          <div style="margin-top: 100px;margin-right: 60px;">
            <div v-for="(item, index) in lcdataList" :key="index">
              <div v-if="lcindex == index + 1">
                <div class="lc-left-titel">
                  <div class="lc-left-titel-left">0{{ index + 1 }}</div>
                  <div class="lc-left-titel-right">{{ item.titel }}</div>
                </div>
                <div class="lc-left-content">
                  {{ item.content }}
                </div>
                <div class="lccontentline">
                  <div class="lccontentline-line"></div>
                </div>
                <div v-for="(item2, index2) in item.list" :key="index2" class="lccontent-listitem">
                  <img src="../assets/youdian.png" alt="" width="16px" height="16px" style="margin-right: 12px;">


                  <div class="lccontent-listitem-content">{{ item2 }}</div>
                </div>
              </div>


            </div>
          </div>

          <div>
            <img src="../assets/LC1@2x.png" alt="" width="560px" height="500px" v-if="lcindex == 1">
            <img src="../assets/LC2@2x.png" alt="" width="560px" height="500px" v-if="lcindex == 2">
            <img src="../assets/LC3@2x.png" alt="" width="560px" height="500px" v-if="lcindex == 3">
            <img src="../assets/LC4@2x.png" alt="" width="560px" height="500px" v-if="lcindex == 4">
            <img src="../assets/LC5@2x.png" alt="" width="560px" height="500px" v-if="lcindex == 5">


          </div>
        </div>
      </div>
      <!-- 新闻 -->
      <div class="newsbg" id="news">
        <div><img src="../assets/news.png" alt="" width="673px" height="104px"></div>
        <div class="newsList">
          <div class="newsitem" v-for="(item, index) in newsListdata" :key="index">
            <div class="newsitem-titel">
              {{ item.newsTitle }}
            </div>
            <div class="news-line"></div>
            <img :src="item.newsImg" alt="" width="360px" height="202px">
            <div class="newsitem-content" v-html="item.newsContent"></div>
            <div class="newsitem-more">
              MORE +
            </div>
          </div>

        </div>
        <div>
          <div class="newsmorebtn">
            MORE +
          </div>
        </div>
      </div>
      <!-- 荣誉 -->
      <div class="rybg">
        <div><img src="../assets/ry.png" alt="" width="530px" height="104px"></div>
        <div class="rycontent">
          在数字经济与实体经济深度融合的时代浪潮下，智派新工平台通过技术创新推动劳务派遣行业从"规模扩张"向"质量提升"转型，既破解了企业弹性用工难题，又保障了劳动者合法权益，更助力监管部门构建数字化治理新范式。这种以科技赋能合规、以平台创造价值的创新实践，正是供给侧结构性改革在人力资源服务领域的生动体现，为构建和谐劳动关系贡献了智慧方案。
        </div>
        <div class="ryimglist">
          <div :class="[ryindex == 1 ? 'activeryimgitem' : 'ryimgitem']" @click="changeryindex(1)">
            <div class="ryitem-top">
              <img src="../assets/renli.png" alt="" class="ryimg">
            </div>
            <div :class="[ryindex == 1 ? 'activeryitem-bottom' : 'ryitem-bottom']">
              国家权威检测质量合格产品重点推广企业
            </div>
          </div>
          <div :class="[ryindex == 2 ? 'activeryimgitem' : 'ryimgitem']" @click="changeryindex(2)">
            <div class="ryitem-top">
              <img src="../assets/sq.png" alt="" class="ryimg">
            </div>
            <div :class="[ryindex == 2 ? 'activeryitem-bottom' : 'ryitem-bottom']">
              授权书
            </div>
          </div>
          <div :class="[ryindex == 3 ? 'activeryimgitem' : 'ryimgitem']" @click="changeryindex(3)">
            <div class="ryitem-top">
              <img src="../assets/qiye.png" alt="" class="ryimg">
            </div>
            <div :class="[ryindex == 3 ? 'activeryitem-bottom' : 'ryitem-bottom']">
              国家权威检测质量合格产品重点推广企业
            </div>
          </div>
          <div :class="[ryindex == 4 ? 'activeryimgitem' : 'ryimgitem']" @click="changeryindex(4)">
            <div class="ryitem-top">
              <img src="../assets/cx.png" alt="" class="ryimg">
            </div>
            <div :class="[ryindex == 4 ? 'activeryitem-bottom' : 'ryitem-bottom']">
              国家权威检测质量合格产品重点推广企业
            </div>
          </div>
          <div :class="[ryindex == 5 ? 'activeryimgitem' : 'ryimgitem']" @click="changeryindex(5)">
            <div class="ryitem-top">
              <img src="../assets/cx.png" alt="" class="ryimg">
            </div>
            <div :class="[ryindex == 5 ? 'activeryitem-bottom' : 'ryitem-bottom']">
              国家权威检测质量合格产品重点推广企业
            </div>
          </div>

        </div>
        <div class="rybottombtn">
          <img src="../assets/zuo.png" alt="" width="40px" height="40px" style="margin-right: 32px;"
            @click="downryindex">
          <img src="../assets/you.png" alt="" width="40px" height="40px" @click="addryindex">
        </div>
      </div>
      <!-- 联系我们 -->
      <div class="usbg" id="us">
        <div class="us-left">
          <div> <img src="../assets/contact us@2x.png" alt="" width="482px" height="94px"></div>
          <div class="us-left-title">联系我们</div>
          <div class="us-left-line"></div>
          <div class="us-left-content">address</div>
          <div class="us-left-content">成都市金牛区大成仓、智汇园B219</div>
          <div class="us-left-content" style="margin-top: 28px;">E-MAIL</div>
          <div class="us-left-content">xgb915101@163.com</div>
          <div class="phonebox">
            <img src="../assets/phone.png" alt="" width="26px" height="26px" style="margin-right: 8px;">
            <div>
              028-8399-8864
            </div>
          </div>

          <div class="ewmList">
            <div class="ewmitem">
              <img src="../assets/ewm.png" alt="" width="116px" height="116px">
              <div>扫码进入小程序端</div>
            </div>
            <div class="ewmbigitem">
              <div style="margin-right: 16px;">
                <img src="../assets/ewm.png" alt="" width="116px" height="116px">
                <div>Ios下载</div>
              </div>
              <div>
                <img src="../assets/ewm.png" alt="" width="116px" height="116px">
                <div>Android下载</div>
              </div>
            </div>
          </div>
        </div>
        <div class="us-right">
          <img src="../assets/map.png" alt="" width="100%" height="100%">
          <div class="us-right-address">
            <img src="../assets/gst.png" alt="" width="260px" height="134px">
            <div class="us-right-address-box">
              <div class="address-box-titel">公司地址：</div>
              <div class="address-box-content">四川省成都市金牛区君信大厦 B座 1103</div>
            </div>

          </div>
          <img src="../assets/dw.png" alt="" width="40px" height="40px" class="us-right-dw">
        </div>

      </div>
      <!-- 尾部 -->
      <div class="footer">
        ©版权所有: 成都智派新工科技有限公司 备案号：蜀ICP备2023030156号
      </div>
    </div>

  </div>
</template>

<script>
// @ is an alias to /src
import img1 from "@/assets/newsimg.png";
import tes1 from "@/assets/TS1@2x.png";
import tes2 from "@/assets/TS2@2x.png";
import tes3 from "@/assets/TS3@2x.png";
import tes4 from "@/assets/TS4@2x.png";
import tes5 from "@/assets/TS5@2x.png";
import { newsList_api } from '@/api/news'
import Swiper from 'swiper';
export default {
  name: 'HomeView',
  components: {

  },
  data() {
    return {
      zoom: 1,
      teseindex: 1,
      mySwiper: null, // 用于保存Swiper实例
      teseimglist: [
        tes1,
        tes2,
        tes3,
        tes4,
        tes5,

      ],
      lcindex: 1,
      lcdataList: [
        {
          titel: '时长选择',
          content: '丰富的时长制度任您选择',
          list: ['丰富的时长制度任您选择', '工作日期无限制按需抉择！', '承包承揽承接、按时按天按件应有尽有']
        },
        {
          titel: '零工抢单',
          content: '不限时间空间随时随地下单！',
          list: ['不限时间空间随时随地下单！', '一键续单、指定派单效率提高！',]
        },
        {
          titel: '签订合同',
          content: '合同签署保障权益！',
          list: ['合同签署保障权益！', '线上存续，无需担心合同丢失！',]
        },
        {
          titel: '工作需求',
          content: '按需筛选快速下单!',
          list: ['按需筛选快速下单!', '智能匹配需求完成招工！',]
        },
        {
          titel: '我的招工',
          content: '招工状态随时查看',
          list: ['招工状态随时查看', '线上管理方便快捷', '一键即可结工，无需线下支付']
        },
      ],
      newsListdata: [
        {
          titel: '恒泰MINISO名创优品正式入驻美团闪购 时尚单品30分钟送达',
          content: '精耕美团闪购与设计师品牌MINISO名创优品共同发布消息，双方即日起正式开展品牌合作，名创精耕美团闪购与设计师品牌MINISO名创优品共同发布消息，双方即日起正式开展品牌合作，名创',
          imgurl: img1
        },
        {
          titel: '恒泰MINISO名创优品正式入驻美团闪购 时尚单品30分钟送达',
          content: '精耕美团闪购与设计师品牌MINISO名创优品共同发布消息，双方即日起正式开展品牌合作，名创精耕美团闪购与设计师品牌MINISO名创优品共同发布消息，双方即日起正式开展品牌合作，名创',
          imgurl: img1
        },
        {
          titel: '恒泰MINISO名创优品正式入驻美团闪购 时尚单品30分钟送达',
          content: '精耕美团闪购与设计师品牌MINISO名创优品共同发布消息，双方即日起正式开展品牌合作，名创精耕美团闪购与设计师品牌MINISO名创优品共同发布消息，双方即日起正式开展品牌合作，名创',
          imgurl: img1
        },
        {
          titel: '恒泰MINISO名创优品正式入驻美团闪购 时尚单品30分钟送达',
          content: '精耕美团闪购与设计师品牌MINISO名创优品共同发布消息，双方即日起正式开展品牌合作，名创精耕美团闪购与设计师品牌MINISO名创优品共同发布消息，双方即日起正式开展品牌合作，名创',
          imgurl: img1
        },

      ],
      ryindex: 3



    }
  },
  created() {
    if (this.IsPhone()) {
      this.$router.push('/phoneHome')
    }
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize()
    this.$nextTick(() => {
      this.initSwiper(); // 初始化Swiper
    });
    this.getnewsList()
  },
  beforeDestroy() {

    window.removeEventListener('resize', this.handleResize)
  },
  methods: {

    IsPhone() {
      var info = navigator.userAgent;
      //通过正则表达式的test方法判断是否包含“Mobile”字符串
      var isPhone = /mobile/i.test(info);
      //如果包含“Mobile”（是手机设备）则返回true
      return isPhone;

    },
    //获取新闻
    getnewsList() {
      newsList_api({
        pageNum: 1,
        pageSize: 4,
      }).then(res => {
        console.log(res);
        this.newsListdata = res.rows
      })
    },
    initSwiper() {

      if (this.mySwiper) {
        this.mySwiper.destroy(); // 销毁旧的Swiper实例
      }
      //   this.mySwiper = new Swiper('.swiper-container', {
      //     effect: 'coverflow',
      // slidesPerView: 5,
      // centeredSlides: true,
      //   });
      let that = this
      this.mySwiper = new Swiper('.swiper-container', {
        loop: true,
        effect: 'coverflow',
        initialSlide: 0,
        slidesPerView: 3,
        centeredSlides: true,
        coverflowEffect: {
          rotate: 0,
          stretch: 10,
          depth: 180,
          modifier: 1,
          slideShadows: false
        },

        on: {

          slideChange: function (swiper) {

            if (this.activeIndex <= 2) {
              that.teseindex = this.activeIndex + 3
            } else if (this.activeIndex == 8) {
              that.teseindex = 1
            } else {
              that.teseindex = this.activeIndex - 2
            }

          },





        },

      });
    },


    handleResize() {
      this.windowWidth = window.innerWidth;
      if (this.windowWidth < 1900) {
        this.zoom = this.windowWidth / 1900 * 0.9
        if (this.zoom < 0.5) {
          this.zoom = 0.5
        }
        console.log(this.zoom, '小了');
      } else if (this.windowWidth > 1900) {
        this.zoom = 1
        console.log(this.zoom, '大了');
      }
    

    },
    tesechange(id) {
      this.teseindex = id

      this.mySwiper.slideTo(id + 2, 1000, false)
    },
    toteimg(id) {
      // console.log(1111);
      // console.log(id);
      this.teseindex = id + 1
      if (id <= 4) {
        this.mySwiper.slideTo(id + 3, 1000, false)
      } else if (id == 8) {
        this.mySwiper.slideTo(1, 1000, false)
      } else {
        this.mySwiper.slideTo(1, 1000, false)

      }
    },

    changelcindex(id) {
      this.lcindex = id
    },
    changeryindex(id) {
      this.ryindex = id

    },
    addryindex() {
      if (this.ryindex < 5) {
        this.ryindex++
      } else {
        this.ryindex = 1
      }
    },
    downryindex() {
      if (this.ryindex > 1) {
        this.ryindex--
      } else {
        this.ryindex = 5
      }
    },
    //滚动到锚点
    scrolltotese() {
      const target = document.getElementById('tese');
      window.scrollTo({ top: target.offsetTop + 100, behavior: 'smooth' });
    },
    scrolltonews() {
      const target = document.getElementById('news');
      window.scrollTo({ top: target.offsetTop + 100, behavior: 'smooth' });
    },
    scrolltous() {
      const target = document.getElementById('us');
      window.scrollTo({ top: target.offsetTop + 100, behavior: 'smooth' });
    },


  }
}
</script>
<style lang="scss">
.topbg {
  position: relative;
  width: 100%;
  min-width: 1200px;
}

.topsub {
  position: absolute;
  top: 0;
  width: 100%;
  min-width: 1200px;
  height: 110px;
  background: rgba(255, 255, 255, 0.04);
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.04);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 100px;
  padding-right: 100px;

  box-sizing: border-box;

  .toptitle {

    font-family: MicrosoftYaHei, MicrosoftYaHei;
    font-weight: bold;
    font-size: 16px;
    color: #FFFFFF;
    line-height: 110px;
    text-align: left;
    font-style: normal;
    display: flex;

    .toptitletext {
      margin-right: 48px;
      cursor: pointer;
    }
  }
}

.centertitel {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -125%);

  .centertiteltext1 {

    font-family: AlibabaPuHuiTiB;
    font-size: 68px;
    color: #FFFFFF;
    line-height: 94px;
    text-shadow: 0px 0px 8px rgba(0, 0, 0, 0);
    text-align: center;
    font-style: normal;
    margin-bottom: 28px;
  }

  .centertiteltext2 {

    font-family: AlibabaPuHuiTiR;
    font-size: 28px;
    color: #FFFFFF;
    line-height: 38px;
    text-shadow: 0px 0px 8px rgba(0, 0, 0, 0);
    text-align: center;
    font-style: normal;
  }
}

//公司简介
.companybg {
  width: 1890px;
  height: 1010px;
  background: #F5F5F5;
  box-sizing: border-box;
  padding: 120px 200px;
  position: relative;
  margin: 0 auto;

  .companycontent {
    display: flex;
    justify-content: space-between;

    .companycontent-left {
      padding-top: 72px;
      margin-right: 178px;

      .companycontent-left-title {

        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        font-size: 32px;
        color: #222222;
        line-height: 48px;
        text-align: left;
        font-style: normal;
        margin-top: 6px;
        margin-bottom: 40px;

      }

      .companycontent-left-content {

        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 16px;
        color: #999999;
        line-height: 32px;
        text-align: left;
        font-style: normal;
      }
    }
  }

  .companylist {
    position: absolute;
    bottom: 192px;
    display: flex;

    .companylistitem {
      width: 264px;
      height: 276px;
      background: #FFFFFF;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

    }

    .companylistitem-text {
      width: 160px;
      height: 28px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 18px;
      color: #222222;
      line-height: 28px;
      text-align: center;
      font-style: normal;
    }

    .companylistitem:hover {

      background: #0063F5;

    }

    .companylistitem-img {
      margin-bottom: 36px;
    }

    .companylistitem-imgactive {
      display: block;
    }

    .companylistitem-imgunactive {
      display: none;
    }

    .companylistitem:hover .companylistitem-text {

      color: #FFFFFF;

    }

    .companylistitem:hover .companylistitem-imgactive {

      display: none;

    }

    .companylistitem:hover .companylistitem-imgunactive {

      display: block;

    }
  }
}

//特色
.tsbg {
  width: 1890px;
  height: 1138px;
  background: #FFFFFF;
  box-sizing: border-box;
  padding: 120px 200px;
  padding-bottom: 0;
  position: relative;
  margin: 0 auto;

  .teselist {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px #EBEBEB solid;
    margin-top: 48px;
    height: 64px;

    .teselistitem {
      position: relative;
      cursor: pointer;

      .tesetitle {

        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        font-size: 26px;
        color: #222222;
        line-height: 36px;
        text-align: center;
        font-style: normal;
      }

      .activetesetitle {
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        font-size: 26px;
        color: #0063F5;
        line-height: 36px;
        text-align: center;
        font-style: normal;
      }

      .teseline {
        width: 120px;
        height: 3px;
        background: linear-gradient(90deg, #0063F5 0%, #00AAFF 100%);
        position: absolute;
        bottom: 0px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }

  .tesecontenttitle {

    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 24px;
    color: #222222;
    line-height: 34px;
    text-align: center;
    font-style: normal;
    margin-top: 42px;
  }

  .teseimglist {
    width: 100%;
    height: 782px;
  }
}

::v-deep .swiper-container {
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
}

::v-deep .swiper-slide {
  background-position: center;
  background-size: cover;
  width: 300px;
  height: 300px;

}

//流程
.lczw {

  height: 56px;
  background: #F5F7FA;
  width: 1890px;
  margin: 0 auto;

}

.lcbg {
  height: 852px;
  background-image: url(../assets/bg@2x.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 1890px;
  margin: 0 auto;
  padding: 64px 360px;
  padding-bottom: 0;
  box-sizing: border-box;

  .lclist {
    position: relative;
    margin-top: 48px;
    display: flex;
    justify-content: space-between;
    padding: 0 32px;

    .lclistitem {
      width: 160px;
      height: 134px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;

      .activelcyuan {
        width: 16px;
        height: 16px;
        background: #0063F5;
        border-radius: 50%;

      }

      .lcyuan {
        box-sizing: border-box;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background: #FFFFFF;
        border: 2px solid #DDDDDD;
      }

      .activelcindex {

        font-family: AlimamaFangYuanTiVF-Bold, AlimamaFangYuanTiVF-Bold;
        font-weight: normal;
        font-size: 40px;
        color: #222222;
        line-height: 48px;
        text-align: center;
        font-style: normal;
      }

      .lcindex {
        font-family: AlimamaFangYuanTiVF-Bold, AlimamaFangYuanTiVF-Bold;
        font-weight: normal;
        font-size: 40px;
        color: #999999;
        line-height: 48px;
        text-align: center;
      }

      .activelctitle {

        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        font-size: 24px;
        color: #222222;
        line-height: 34px;
        text-align: center;
        font-style: normal;
      }

      .lctitle {

        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 24px;
        color: #999999;
        line-height: 34px;
        text-align: center;
        font-style: normal;
      }
    }

    .lcline1 {
      position: absolute;
      top: 6px;
      left: 0px;
      width: 110px;
      height: 2px;
      background: #0063F5;
    }

    .lcline2 {
      position: absolute;
      top: 6px;
      left: 120px;
      width: 221px;
      height: 2px;
      background: #DDDDDD;
    }

    .activelcline2 {
      position: absolute;
      top: 6px;
      left: 120px;
      width: 221px;
      height: 2px;
      background: #0063F5;
    }

    .lcline3 {
      position: absolute;
      top: 6px;
      left: 356px;
      width: 222px;
      height: 2px;
      background: #DDDDDD;
    }

    .activelcline3 {
      position: absolute;
      top: 6px;
      left: 356px;
      width: 222px;
      height: 2px;
      background: #0063F5;
    }

    .lcline4 {
      position: absolute;
      top: 6px;
      left: 593px;
      width: 222px;
      height: 2px;
      background: #DDDDDD;
    }

    .activelcline4 {
      position: absolute;
      top: 6px;
      left: 593px;
      width: 222px;
      height: 2px;
      background: #0063F5;
    }

    .lcline5 {
      position: absolute;
      top: 6px;
      left: 829px;
      width: 222px;
      height: 2px;
      background: #DDDDDD;
    }

    .activelcline5 {
      position: absolute;
      top: 6px;
      left: 829px;
      width: 222px;
      height: 2px;
      background: #0063F5;
    }

    .lcline6 {
      position: absolute;
      top: 6px;
      left: 1066px;
      width: 110px;
      height: 2px;
      background: #DDDDDD;
    }
  }

  .lccontent {
    display: flex;
    justify-content: space-between;

    .lc-left-titel {
      display: flex;
      align-items: center;

      .lc-left-titel-left {

        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        font-size: 24px;
        color: #0063F5;
        line-height: 33px;
        text-align: left;
        font-style: normal;
        margin-right: 6px;
      }

      .lc-left-titel-right {

        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        font-size: 24px;
        color: #222222;
        line-height: 33px;
        text-align: left;
        font-style: normal;
      }

    }

    .lc-left-content {
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 16px;
      color: #222222;
      line-height: 22px;
      text-align: left;
      font-style: normal;
      margin-top: 8px;
      margin-bottom: 28px;
    }

    .lccontentline {
      position: relative;
      width: 580px;
      height: 1px;
      background: #E0E8F4;
      margin-bottom: 32px;

      .lccontentline-line {
        position: absolute;
        top: -3px;
        width: 24px;
        height: 3px;
        background: #222222;
      }
    }

    .lccontent-listitem {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 32px;

      .lccontent-listitem-content {


        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 16px;
        color: #222222;
        line-height: 22px;
        text-align: left;
        font-style: normal;

      }
    }
  }
}

//新闻
.newsbg {
  width: 1890px;
  height: 956px;
  background: #FFFFFF;
  margin: 0 auto;
  padding: 120px;
  box-sizing: border-box;

  .newsList {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;

    .newsitem {
      width: 400px;
      height: 540px;
      background: #FFFFFF;
      border-radius: 8px;
      box-sizing: border-box;
      padding: 28px;
      cursor: pointer;

      .newsitem-titel {

        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        font-size: 20px;
        color: #222222;
        line-height: 38px;
        text-align: left;
        font-style: normal;
        margin-bottom: 32px;
      }

      .news-line {
        width: 28px;
        height: 4px;
        background: #222222;
        margin-bottom: 30px;
      }

      .newsitem-content {

        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 16px;
        color: #999999;
        line-height: 28px;
        text-align: left;
        font-style: normal;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        /* 限制显示行数 */
        -webkit-box-orient: vertical;
        // margin-top: 30px;
        // margin-bottom: 34px;
      }

      .newsitem-more {

        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #999999;
        line-height: 20px;
        text-align: left;
        font-style: normal;
      }
    }

    .newsitem:hover {
      box-shadow: 0px 8px 24px 0px rgba(221, 228, 240, 0.52);
      border-radius: 8px;
    }

    .newsitem:hover .newsitem-titel {
      color: #0063F5;
    }

    .newsitem:hover .newsitem-more {
      color: #0063F5;
    }

    .newsitem:hover .news-line {
      background: #0063F5;
    }
  }

  .newsmorebtn {
    width: 140px;
    height: 32px;
    background: #FFFFFF;
    border-radius: 16px;
    border: 1px solid #DDDDDD;
    margin: 0 auto;
    margin-top: 20px;

    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    font-size: 12px;
    color: #222222;
    line-height: 32px;
    text-align: centers;
    font-style: normal;
    cursor: pointer;
  }

}

//荣誉
.rybg {
  width: 1890px;
  height: 887px;
  background: #F5FAFF;
  margin: 0 auto;
  padding: 120px;
  box-sizing: border-box;

  .rycontent {
    width: 1320px;
    height: 64px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 16px;
    color: #222222;
    line-height: 32px;
    text-align: left;
    font-style: normal;
    margin: 0 auto;
    margin-top: 48px;
  }

  .ryimglist {
    display: flex;
    margin-top: 48px;
    align-items: baseline;
    justify-content: space-between;

    .activeryimgitem {
      width: 336px;
      height: 311px;
      border-radius: 8px;
      border: 2px solid #0063F5;
      cursor: pointer;

      .ryitem-top {
        box-sizing: border-box;
        width: 336px;
        height: 234px;
        padding: 10px;
        background: #FFFFFF;
        border-radius: 8px 8px 0px 0px;

        .ryimg {
          width: 304px;
          height: 219px;
        }
      }

      .activeryitem-bottom {
        width: 336px;
        height: 76px;
        background: linear-gradient(#C2DBFF 0%, #FFFFFF 100%);
        box-shadow: 0px 6px 24px 0px #DDE4F0;
        border-radius: 0px 0px 8px 8px;
        font-family: MicrosoftYaHei;
        font-size: 16px;
        color: #222222;
        text-align: center;
        font-style: normal;
        text-transform: uppercase;
        padding: 16px 20px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
      }

    }

    .ryimgitem {
      cursor: pointer;

      .ryitem-top {
        width: 290px;
        height: 205px;
        background: #FFFFFF;
        border-radius: 8px 8px 0px 0px;


        .ryimg {
          width: 274px;
          height: 197px;
        }
      }

      .ryitem-bottom {
        width: 290px;
        height: 76px;
        background: linear-gradient(#E9F0FF 0%, #FFFFFF 100%);
        box-shadow: 0px 6px 24px 0px #DDE4F0;
        border-radius: 0px 0px 8px 8px;
        border: 2px solid #FFFFFF;
        font-family: MicrosoftYaHei;
        font-size: 16px;
        color: #222222;
        text-align: center;
        font-style: normal;
        text-transform: uppercase;
        padding: 16px 20px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .rybottombtn {
    margin-top: 32px;
    display: flex;
    justify-content: center;
    cursor: pointer;

  }
}

//联系我们
.usbg {
  width: 1890px;
  height: 930px;
  background: #F5F5F5;
  margin: 0 auto;

  box-sizing: border-box;
  display: flex;

  .us-left {
    width: 880px;
    height: 930px;
    background: #F5F5F5;
    box-sizing: border-box;
    padding: 96px 187px;

    .us-left-title {
      height: 44px;
      font-family: AlibabaPuHuiTiM;
      font-size: 32px;
      color: #222222;
      line-height: 44px;
      text-align: left;
      font-style: normal;
      margin-bottom: 48px;
    }

    .us-left-line {
      width: 68px;
      height: 8px;
      background: #222222;
      margin-bottom: 28px;
    }

    .us-left-content {

      height: 20px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 14px;
      color: #222222;
      line-height: 20px;
      text-align: left;
      font-style: normal;
      text-transform: uppercase;
    }

    .phonebox {
      display: flex;
      align-items: baseline;
      margin-top: 72px;

      font-family: DIN, DIN;
      font-weight: bold;
      font-size: 60px;
      color: #0063F5;
      line-height: 72px;
      text-align: left;
      font-style: normal;

    }

    .ewmList {
      margin-top: 72px;
      display: flex;

      .ewmitem {
        width: 140px;
        height: 168px;
        background: #EBEBEB;
        border-radius: 12px;
        margin-right: 24px;
        box-sizing: border-box;
        padding: 12px;

        font-family: AlibabaPuHuiTiR;
        font-size: 14px;
        color: #222222;
        line-height: 20px;
        text-align: center;
        font-style: normal;

      }

      .ewmbigitem {
        display: flex;
        width: 272px;
        height: 168px;
        background: #EBEBEB;
        border-radius: 12px;
        box-sizing: border-box;
        padding: 12px;

      }
    }
  }

  .us-right {
    position: relative;

    .us-right-address {
      position: absolute;
      left: 188px;
      top: 264px;
      width: 646px;
      height: 134px;
      background: #FFFFFF;
      box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.08);
      border-radius: 12px;
      display: flex;
      align-items: center;

      .us-right-address-box {
        margin-left: 24px;

        .address-box-titel {
          width: 348px;
          height: 28px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 20px;
          color: #1A1A1A;
          line-height: 28px;
          text-align: left;
          font-style: normal;
        }

        .address-box-content {
          width: 348px;
          height: 22px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 16px;
          color: #555555;
          line-height: 22px;
          text-align: left;
          font-style: normal;
          margin-top: 4px;
        }
      }
    }

    .us-right-dw {
      position: absolute;
      left: 492px;
      top: 423px;
    }

  }
}

//weibu
.footer {
  width: 1890px;
  height: 61px;
  background: #FFFFFF;
  margin: 0 auto;
  box-sizing: border-box;
  width: 456px;
  height: 20px;
  font-family: MicrosoftYaHei;
  font-size: 14px;
  color: #999999;
  line-height: 61px;
  text-align: center;
  font-style: normal;

}
</style>
