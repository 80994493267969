import request from '@/utils/request'


// 获取网络列表
export function newsList_api(query) {
  return request({
    url: '/api/complaint/getNewsList',
    method: 'get',
    params: query
  })
}