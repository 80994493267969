import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/phoneHome',
    name: 'phoneHome',
  
    component: () => import(/* webpackChunkName: "agreement" */ '../views/phoneHome.vue')
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/agreement',
    name: 'agreement',
   
    component: () => import(/* webpackChunkName: "agreement" */ '../views/Agreement.vue')
  },
  {
    path: '/privacy',
    name: 'privacy',
   
    component: () => import(/* webpackChunkName: "agreement" */ '../views/Privacy.vue')
  },
]

const router = new VueRouter({
  routes
})

export default router
